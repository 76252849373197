html,
body {
  min-height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.react-hot-toast {
  margin-top: 70px;
  text-transform: none;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after {
  background: #1f202a;
  font-weight: bolder;
}

.gm-style-iw-tc {
  display: none;
}

.gm-ui-hover-effect span {
  display: none !important;
}
